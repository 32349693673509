import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import type { ButtonEmphasis } from "clutch/src/Button/Button.jsx";
import { Button } from "clutch/src/Button/Button.jsx";

import { readState } from "@/__main__/app-state.mjs";
import type { ActiveRoute } from "@/__main__/router.mjs";
import { removeSnackbarMessage } from "@/app/actions.mjs";
import {
  CloseButton,
  CloseProgressIndicator,
  SnackbarContainer,
  SnackbarIcon,
  SnackbarMessage,
  SnackbarMessageContent,
} from "@/app/Snackbar.style.jsx";
import CloseIcon from "@/inline-assets/close.svg";
import { useInertTransition } from "@/shared/Transition.jsx";
import deepEqual from "@/util/deep-equal.mjs";
import globals from "@/util/global-whitelist.mjs";
import nextFrame from "@/util/next-frame.mjs";
import useCallbackRef from "@/util/use-callback-ref.mjs";
import { useClientLayoutEffect } from "@/util/use-client-layout-effect.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

export type SnackbarIcon =
  | {
      icon?:
        | string
        | {
            type: "image";
            src: string;
            alt?: string;
            zoom?: number;
            preserveAspectRatio?: boolean;
          };
      Icon?: never;
    }
  | {
      icon?: never;
      Icon?: React.JSXElementConstructor<unknown>;
    };

export type SnackbarText =
  | {
      text?: Translation | (() => Translation);
      Text?: never;
    }
  | {
      text?: never;
      Text?: React.JSXElementConstructor<unknown>;
    };

/**
 * A restricted subset of `<Button />` props. This is to ensure that the
 * Snackbar can be properly serialized for mobile.
 */
export type SnackbarAction = {
  emphasis?: ButtonEmphasis;

  iconLeft?: React.ReactNode;
  iconRight?: React.ReactNode;

  text: Translation | (() => Translation);

  bgColor?: string;
  bgColorHover?: string;
  textColor?: string;
  textColorHover?: string;

  href?: string;
  target?: string;

  /**
   * Called on click. If `href` is provided, this will be called before
   * navigating to the link.
   */
  onClick?: () => Promise<void> | void;

  dismiss?: boolean;
};

export type SnackbarActions = {
  actions?: SnackbarAction[];
};

export type SnackbarCloseMethods = {
  dismissable?: boolean;
  closeAfter?: number;
};

export type SnackbarDismissedByRouting = {
  dismissOnRouteChange?: (route: ActiveRoute) => boolean | boolean;
};

export type SnackbarMessageInit = {
  /**
   * A snackbar with `high` priority will be put at the top of the snackbar
   * queue, showing immediately. This is the default.
   *
   * A snackbar with `low` priority will be put at the bottom of the queue.
   */
  priority?: "high" | "low";

  /**
   * The snackbar's identifier. Only one snackbar with a given `id` is
   * permitted in the queue, with old ones being removed and replaced.
   */
  id?: string;

  /**
   * Called when the snackbar is manually dismissed, timed out, or
   * removed through a completed action.
   *
   * It will not fire if the snackbar is replaced by another snackbar
   * with the same `id`, or was removed using `removeSnackbarMessage`.
   */
  onDismissed?: () => void;
} & SnackbarIcon &
  SnackbarText &
  SnackbarActions &
  SnackbarCloseMethods &
  SnackbarDismissedByRouting;

export type SnackbarMessage = {
  id: string | number;

  onDismissed?: () => void;
} & SnackbarIcon &
  SnackbarText &
  SnackbarActions &
  SnackbarCloseMethods &
  SnackbarDismissedByRouting;

declare module "@/__main__/app-state.mjs" {
  interface VolatileState {
    snackbar?: SnackbarMessage[];
  }
}

const VISIBLE_PROPS: Array<keyof SnackbarMessage> = [
  "id",
  "text",
  "Text",
  "icon",
  "Icon",
];

const VISIBLE_ACTION_PROPS: Array<keyof SnackbarAction> = [
  "emphasis",
  "text",
  "iconLeft",
  "iconRight",
];

/**
 * Checks if two messages are visible equal. This is used to determine if a snackbar
 * should be animated when being replaced.
 */
function isMessageSimilar(msg1: SnackbarMessage, msg2: SnackbarMessage) {
  if (msg1 === msg2) return true;

  for (const prop of VISIBLE_PROPS) {
    if (!deepEqual(msg1[prop], msg2[prop])) {
      return false;
    }
  }

  if (msg1.actions?.length !== msg2.actions?.length) return false;

  for (let i = 0; i < msg1.actions?.length; i++) {
    const action1 = msg1.actions?.[i];
    const action2 = msg2.actions?.[i];

    for (const prop of VISIBLE_ACTION_PROPS) {
      if (!deepEqual(action1?.[prop], action2?.[prop])) {
        return false;
      }
    }
  }

  return true;
}

const generateMessageKey = () => Math.random();

// REFACTOR(deli): there is something wrong with how animations were implemented here.
// In general, the animation interferes with how this component behaves functionally,
// this should almost never be the case. There is some logic in here that may not make
// sense simply because I removed animation-specific logic.
//
// The behavior needs to be agnostic about animations.
// This can be accomplished with Transition component, or raw DOM if that isn't feasible.
export default function Snackbar() {
  const {
    volatile: { snackbar, shouldOmitBottomContent },
  } = useSnapshot(readState);

  const [canShowSnackbar, setCanShowSnackbar] = useState<boolean>(false);
  useClientLayoutEffect(() => {
    if (shouldOmitBottomContent) {
      setCanShowSnackbar(false);
      return;
    }
    const t = setTimeout(() => setCanShowSnackbar(true), 500);
    return () => clearTimeout(t);
  }, [shouldOmitBottomContent]);

  const mostRecentMessage =
    snackbar && snackbar.length
      ? (snackbar[snackbar.length - 1] as SnackbarMessage)
      : null;

  const containerRef = useInertTransition<HTMLDivElement>({
    anim: { leaveTo: "hide" },
  });
  const messageRef = useInertTransition<HTMLDivElement>();
  const [messageKey, setMessageKey] = useState(generateMessageKey());

  const [currentMessage, setCurrentMessage] = useState<SnackbarMessage | null>(
    null,
  );

  const [isActionPending, setActionPending] = useState<boolean>(false);

  const dismissCurrentMessage = useCallback(() => {
    if (currentMessage) {
      currentMessage.onDismissed?.();
      removeSnackbarMessage(currentMessage.id);
    }
  }, [currentMessage]);

  const indicatorRef = useCallbackRef(
    (node: HTMLDivElement) => {
      const { closeAfter } = node.dataset;
      if (!closeAfter || isActionPending) return;

      const cleanup = [];

      const { frame } = nextFrame(() => {
        node.classList.add("to");
        const t = setTimeout(
          dismissCurrentMessage,
          Number.parseInt(closeAfter, 10),
        );

        cleanup.push(() => clearTimeout(t));
      });

      cleanup.push(() => cancelAnimationFrame(frame));

      return () => cleanup.forEach((fn) => fn());
    },
    [dismissCurrentMessage, isActionPending],
  );

  useEffect(() => {
    // Hack to get around strict React calling useEffect() multiple times
    // If we don't do this, the open animation will be played twice
    //
    // We could get around this by delaying adding snackbars until the whole
    // app is mounted, but we cannot predict what future implementors will do
    let isMounted = true;

    queueMicrotask(() => {
      if (isMounted) {
        if (!mostRecentMessage) {
          setCurrentMessage(null);
        } else {
          setActionPending(false);
          setCurrentMessage(mostRecentMessage);
          if (
            !currentMessage ||
            !isMessageSimilar(currentMessage, mostRecentMessage)
          ) {
            setMessageKey(generateMessageKey());
          }
        }
      }
    });

    return () => {
      isMounted = false;
    };
  }, [mostRecentMessage, currentMessage, setCurrentMessage]);

  const onClickAction = useCallback(
    async (e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
      // This is designed this way so that `onClick` always runs, even if `href`
      // is attached to the button.

      e.preventDefault();

      let action: SnackbarAction | undefined;

      try {
        const actionIndex = parseInt(e.currentTarget.dataset["action"]);

        action = currentMessage.actions?.[actionIndex];

        const result = action?.onClick?.();

        if (result instanceof Promise) {
          setActionPending(true);

          // Wait for the action to complete before clearing the message
          await result;
        }

        if (action.dismiss !== false) {
          dismissCurrentMessage();
        }
      } catch (e: unknown) {
        if (action.dismiss !== false) {
          dismissCurrentMessage();
        }

        throw e;
      }

      if ("href" in e.currentTarget) {
        globals.open(e.currentTarget.href, e.currentTarget.target ?? "_self");
      }
    },
    [currentMessage?.actions, dismissCurrentMessage],
  );

  const { t } = useTranslation();

  if (!canShowSnackbar || !currentMessage) return null;

  const { icon, Icon, text, Text, actions, dismissable, closeAfter } =
    currentMessage;

  return (
    <SnackbarContainer ref={containerRef} data-anim-init>
      <SnackbarMessage key={messageKey} ref={messageRef} data-anim-init>
        <SnackbarMessageContent>
          {(Icon || icon) && (
            <div className="icon">
              {Icon && React.createElement(Icon)}
              {icon &&
                (typeof icon === "string" ? (
                  icon
                ) : (
                  <SnackbarIcon $zoom={icon.zoom}>
                    <img src={icon.src} alt={icon.alt} />
                  </SnackbarIcon>
                ))}
            </div>
          )}

          <p>
            {Text && React.createElement(Text)}
            {text && t(...(typeof text === "function" ? text() : text))}
          </p>

          {actions?.map((action, index) => (
            <Button
              key={index}
              emphasis={action.emphasis || "high"}
              disabled={isActionPending}
              data-action={index}
              onClick={onClickAction}
              {...(action.href ? { href: action.href } : {})}
              {...(action.target ? { target: action.target } : {})}
              {...(action.bgColor ? { bgColor: action.bgColor } : {})}
              {...(action.bgColorHover
                ? { bgColorHover: action.bgColorHover }
                : {})}
              {...(action.textColor ? { textColor: action.textColor } : {})}
              {...(action.textColorHover
                ? { textColorHover: action.textColorHover }
                : {})}
              {...(action.iconLeft ? { iconLeft: action.iconLeft } : {})}
              {...(action.iconRight ? { iconRight: action.iconRight } : {})}
            >
              {t(
                ...(typeof action.text === "function"
                  ? action.text()
                  : action.text),
              )}
            </Button>
          ))}

          {dismissable !== false && (
            <CloseButton
              disabled={isActionPending}
              onClick={dismissCurrentMessage}
              aria-label={t("common:close", "Close")}
            >
              <CloseIcon />
            </CloseButton>
          )}
        </SnackbarMessageContent>

        {closeAfter !== undefined && !isActionPending && (
          <CloseProgressIndicator
            ref={indicatorRef}
            data-close-after={closeAfter}
            style={{
              transitionDuration: `${closeAfter}ms`,
            }}
          />
        )}
      </SnackbarMessage>
    </SnackbarContainer>
  );
}
