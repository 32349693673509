import React from "react";
import { styled } from "goober";
import { mobile } from "clutch/src/Style/style.mjs";

import { CONTENT_HEADER_Z_INDEX } from "@/app/constants.mjs";

export const TIMINGS = {
  bar: {
    show: 0.5,
    hide: 0.75,
  },
  message: {
    show: 0.25,
    hide: 0.25,
  },
};

export const SnackbarContainer = styled("div", React.forwardRef)`
  position: sticky;
  z-index: ${CONTENT_HEADER_Z_INDEX};
  bottom: 0;
  left: 0;
  right: 0;

  height: 0;

  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: center;

  &::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: calc(var(--sp-1) * 40);
    background: radial-gradient(
      farthest-side at bottom,
      var(--shade10) 0%,
      rgba(14, 16, 21, 0) 100%
    );
  }

  pointer-events: none;
  user-select: none;

  ${mobile} {
    padding: 0;

    background: none;

    z-index: ${CONTENT_HEADER_Z_INDEX + 1};
  }

  transition: var(--transition-duration) var(--ease-out-quad);
  transition-property: opacity, background-size;

  &.hide {
    position: absolute;
    opacity: 0;
  }
`;

export const SnackbarMessage = styled("div", React.forwardRef)`
  --bg: var(--shade10);
  --text: var(--shade0);

  position: absolute;
  bottom: var(--sp-6);
  max-width: var(--snackbar-max-width, 100%);

  color: var(--text);
  background: var(--bg);
  border: none;
  border-radius: var(--br-lg);
  box-shadow: 0 0 var(--sp-4) var(--shade10);

  overflow: hidden;

  pointer-events: all;
  user-select: none;

  ${mobile} {
    bottom: 0;

    width: 100%;
  }

  transition: calc(var(--transition-duration) * 3) var(--ease-out-expo);
  transition-property: opacity, transform;
  &.leave {
    position: absolute;
    z-index: -1;
  }
  &.leave-to {
    opacity: 0;
    transform: scale(85%);
  }
  &.enter-from {
    opacity: 80%;
    transform: translateY(100%) scale(90%);
  }
`;

export const SnackbarMessageContent = styled("div")<{
  $preserveAspectRatio?: boolean;
}>`
  --bg: var(--shade10);
  --text: var(--shade0);

  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--sp-2);

  padding: var(--sp-2);

  & > .icon {
    width: var(--sp-6);
    height: var(--sp-6);
    margin: var(--sp-2);
    margin-right: 0;

    font-size: var(--sp-5);
    line-height: 100%;

    svg {
      width: 100%;
      height: 100%;
    }

    img {
      width: 100%;
      height: auto;
    }
  }

  p {
    padding: var(--sp-2);
    padding-left: var(--sp-1);

    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
  }

  button {
    flex-shrink: 0;
  }

  p:first-child {
    padding-left: var(--sp-2);
  }

  p + * {
    margin-left: var(--sp-4);
  }
`;

export const SnackbarIcon = styled("div")<{
  $zoom?: number;
}>`
  border-radius: var(--br);

  overflow: hidden;

  ${(props) => props.$zoom && `img { transform: scale(${props.$zoom}); }`}
`;

export const CloseButton = styled("button")`
  background: transparent;

  cursor: pointer;

  svg {
    width: var(--sp-5);
    height: var(--sp-5);

    color: var(--shade2);

    transition: color var(--transition);
  }

  &:hover svg {
    color: var(--shade0);

    &::before {
      opacity: 0.15;
    }
  }

  &:disabled {
    svg {
      color: var(--shade5);
    }
  }
`;

export const CloseProgressIndicator = styled("div", React.forwardRef)`
  width: 0%;
  height: var(--sp-0_5);

  background-color: var(--primary);

  transition: width 0s linear;
  &.to {
    width: 100%;
  }
`;
